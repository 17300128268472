import React from 'react'

import { StorePage } from '../../components'

class Winkel extends React.Component {
  render() {
    return <StorePage />
  }
}

export default Winkel
